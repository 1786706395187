import React, { Suspense, useEffect, useRef, useState } from "react";
import Auth from "libs/auth/components/auth";
import Loading from "libs/components/loading";
import { Route, Switch } from "react-router-dom";
import LogoPNG from "images/logo.png";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { getSubDomain } from "libs/utils/urlUtil";

const SelectWorkspaceDashboard = React.lazy(() =>
  import("app/dashboard/select-workspace-dashboard")
);
const AuthProvider = React.lazy(() =>
  import("libs/auth/components/auth-provider")
);
const PrivacyPolicy = React.lazy(() => import("app/legal/privacy-policy"));
const Tos = React.lazy(() => import("app/legal/tos"));
const Dashboard = React.lazy(() => import("app/dashboard"));
const Landing = React.lazy(() => import("app/landing"));
const Changelog = React.lazy(() => import("app/landing/components/changelog"));
const Plugins = React.lazy(() => import("app/landing/components/plugins"));
const Pricing = React.lazy(() => import("app/landing/components/pricing"));
const BorrowerSite = React.lazy(() => import("app/borrower-site"));
const TrialExpired = React.lazy(() => import("app/trial-expired"));

const PermissionUserRegister = React.lazy(() =>
  import("app/permission-user/register")
);
const AdminSite = React.lazy(() => import("app/admin-site"));
const ContactUs = React.lazy(() => import("app/landing/components/contact-us"));

export const FREE_TRIAL_USERS = 3

export default function App() {
  const [isSubDomain, setIsSubDomain] = useState(getSubDomain());

  const history = useHistory();
  const location = useLocation();

  //check if it is imediately a user has signed in
  useEffect(() => {
    const query = new URLSearchParams(location.search);

    const signedin = query.get("signedin");

    if (signedin) {
      history.replace("/select-workspace");
    }
  }, []);

  return isSubDomain ? (
    isSubDomain === "admin-stats416892" ? (
      <Suspense fallback={<Loading screen />}>
        <AuthProvider
          cookieNames={{ email: "admin-email", jwt: "admin-jwt" }}

        >
          <AdminSite />
        </AuthProvider>
      </Suspense>
    ) : (
      <Suspense fallback={<Loading screen />}>
        <AuthProvider
          cookieNames={{ email: "site-email", jwt: "site-jwt" }}

        >
          <BorrowerSite />
        </AuthProvider>
      </Suspense>
    )
  ) : (
    <Switch>
      <Route path="/trial-expired">
        <Suspense fallback={<Loading screen />}>
          <AuthProvider >
            <TrialExpired />
          </AuthProvider>
        </Suspense>
      </Route>
      <Route path="/pricing">
        <Suspense fallback={<Loading screen />}>
          <Pricing />
        </Suspense>
      </Route>
      <Route path="/permission-user">
        <Suspense fallback={<Loading screen />}>
          <AuthProvider >
            <PermissionUserRegister />
          </AuthProvider>
        </Suspense>
      </Route>
      <Route path="/tos">
        <Suspense fallback={<Loading screen />}>
          <Tos />
        </Suspense>
      </Route>
      <Route path="/privacy-policy">
        <Suspense fallback={<Loading screen />}>
          <PrivacyPolicy />
        </Suspense>
      </Route>
      <Route path="/plugins">
        <Suspense fallback={<Loading screen />}>
          <Plugins />
        </Suspense>
      </Route>
      <Route path="/changelog">
        <Suspense fallback={<Loading screen />}>
          <Changelog />
        </Suspense>
      </Route>

      <Route path="/contact">
        <Suspense fallback={<Loading screen />}>
          <AuthProvider >
            <ContactUs />
          </AuthProvider>
        </Suspense>
      </Route>

      <Route path="/select-workspace">
        <Suspense fallback={<Loading screen />}>
          <AuthProvider>
            <SelectWorkspaceDashboard />
          </AuthProvider>
        </Suspense>
      </Route>

      <Route path="/">
        <Auth
          authProvider={
            <AuthProvider

            />
          }
          landingComponent={<Landing />}
          dashboardComponent={<Dashboard />}
          logo={
            <img src={LogoPNG} className={"h-16 w-16 p-2"} alt="Lendbox logo" />
          }
        />
      </Route>
    </Switch>
  );
}
